import Vue from '@/pages'
import App from './App.vue'
import router from './router'
import store from './store'
import SubsystemInitializer from './pages/subsystem-initializer'

new Vue({
    mixins: [SubsystemInitializer],
    router,
    store,
    render: h => h(App)
}).$mount('#app');
